import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _751369c0 = () => interopDefault(import('../pages/daftar-implementasi-pesantren/index.vue' /* webpackChunkName: "pages/daftar-implementasi-pesantren/index" */))
const _00aa5926 = () => interopDefault(import('../pages/login-history/index.vue' /* webpackChunkName: "pages/login-history/index" */))
const _1a2f94f3 = () => interopDefault(import('../pages/ppob/index.vue' /* webpackChunkName: "pages/ppob/index" */))
const _312e219f = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _427f035f = () => interopDefault(import('../pages/read-more.vue' /* webpackChunkName: "pages/read-more" */))
const _3e577fab = () => interopDefault(import('../pages/santri/index.vue' /* webpackChunkName: "pages/santri/index" */))
const _1cb4447c = () => interopDefault(import('../pages/admin/home.vue' /* webpackChunkName: "pages/admin/home" */))
const _178818c2 = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _ea16e646 = () => interopDefault(import('../pages/auth/register.vue' /* webpackChunkName: "pages/auth/register" */))
const _212802f1 = () => interopDefault(import('../pages/daftar-implementasi-pesantren/success.vue' /* webpackChunkName: "pages/daftar-implementasi-pesantren/success" */))
const _560c402c = () => interopDefault(import('../pages/host/flip/index.vue' /* webpackChunkName: "pages/host/flip/index" */))
const _7b9d37c6 = () => interopDefault(import('../pages/host/midtrans/index.vue' /* webpackChunkName: "pages/host/midtrans/index" */))
const _0cc8da46 = () => interopDefault(import('../pages/host/otto/index.vue' /* webpackChunkName: "pages/host/otto/index" */))
const _ce79f644 = () => interopDefault(import('../pages/host/pay.vue' /* webpackChunkName: "pages/host/pay" */))
const _3c551658 = () => interopDefault(import('../pages/login-history/create.vue' /* webpackChunkName: "pages/login-history/create" */))
const _856bdc90 = () => interopDefault(import('../pages/page/cek-status-booking/index.vue' /* webpackChunkName: "pages/page/cek-status-booking/index" */))
const _51335368 = () => interopDefault(import('../pages/page/kontak-kami.vue' /* webpackChunkName: "pages/page/kontak-kami" */))
const _a1824ef8 = () => interopDefault(import('../pages/page/peta-situs.vue' /* webpackChunkName: "pages/page/peta-situs" */))
const _01e81cec = () => interopDefault(import('../pages/page/privacy-policy.vue' /* webpackChunkName: "pages/page/privacy-policy" */))
const _0d77a92f = () => interopDefault(import('../pages/page/tentang-kami.vue' /* webpackChunkName: "pages/page/tentang-kami" */))
const _20efbdb3 = () => interopDefault(import('../pages/santri/profil.vue' /* webpackChunkName: "pages/santri/profil" */))
const _ccc93cf4 = () => interopDefault(import('../pages/admin/core/akun-keuangan/index.vue' /* webpackChunkName: "pages/admin/core/akun-keuangan/index" */))
const _5a04ef52 = () => interopDefault(import('../pages/admin/core/buku-besar/index.vue' /* webpackChunkName: "pages/admin/core/buku-besar/index" */))
const _8cb05978 = () => interopDefault(import('../pages/admin/core/jurnal-khusus/index.vue' /* webpackChunkName: "pages/admin/core/jurnal-khusus/index" */))
const _0d9965b2 = () => interopDefault(import('../pages/admin/core/jurnal-penyesuaian/index.vue' /* webpackChunkName: "pages/admin/core/jurnal-penyesuaian/index" */))
const _5325091b = () => interopDefault(import('../pages/admin/core/jurnal-umum/index.vue' /* webpackChunkName: "pages/admin/core/jurnal-umum/index" */))
const _15d7ef94 = () => interopDefault(import('../pages/admin/core/kertas-kerja/index.vue' /* webpackChunkName: "pages/admin/core/kertas-kerja/index" */))
const _5a2ba9ce = () => interopDefault(import('../pages/admin/core/laba-rugi/index.vue' /* webpackChunkName: "pages/admin/core/laba-rugi/index" */))
const _cc5d9fd8 = () => interopDefault(import('../pages/admin/core/laporan-keuangan/index.vue' /* webpackChunkName: "pages/admin/core/laporan-keuangan/index" */))
const _6be0d1ac = () => interopDefault(import('../pages/admin/core/neraca/index.vue' /* webpackChunkName: "pages/admin/core/neraca/index" */))
const _85dea744 = () => interopDefault(import('../pages/admin/customer-service/blast-wa/index.vue' /* webpackChunkName: "pages/admin/customer-service/blast-wa/index" */))
const _ee643702 = () => interopDefault(import('../pages/admin/customer-service/nasabah/index.vue' /* webpackChunkName: "pages/admin/customer-service/nasabah/index" */))
const _610006da = () => interopDefault(import('../pages/admin/customer-service/registrasi/index.vue' /* webpackChunkName: "pages/admin/customer-service/registrasi/index" */))
const _4c9941e4 = () => interopDefault(import('../pages/admin/customer-service/rekening-koran/index.vue' /* webpackChunkName: "pages/admin/customer-service/rekening-koran/index" */))
const _6d2e57aa = () => interopDefault(import('../pages/admin/customer-service/rekening-wali/index.vue' /* webpackChunkName: "pages/admin/customer-service/rekening-wali/index" */))
const _194bb80c = () => interopDefault(import('../pages/admin/customer-service/riwayat-transfer/index.vue' /* webpackChunkName: "pages/admin/customer-service/riwayat-transfer/index" */))
const _3f6f6be3 = () => interopDefault(import('../pages/admin/customer-service/tagihan-spp/index.vue' /* webpackChunkName: "pages/admin/customer-service/tagihan-spp/index" */))
const _a5426202 = () => interopDefault(import('../pages/admin/customer-service/transaksi/index.vue' /* webpackChunkName: "pages/admin/customer-service/transaksi/index" */))
const _14001366 = () => interopDefault(import('../pages/admin/customer-service/wali/index.vue' /* webpackChunkName: "pages/admin/customer-service/wali/index" */))
const _e6231582 = () => interopDefault(import('../pages/admin/master/bank/index.vue' /* webpackChunkName: "pages/admin/master/bank/index" */))
const _441b2ebf = () => interopDefault(import('../pages/admin/master/bcn-bank/index.vue' /* webpackChunkName: "pages/admin/master/bcn-bank/index" */))
const _dc280a42 = () => interopDefault(import('../pages/admin/master/document-type/index.vue' /* webpackChunkName: "pages/admin/master/document-type/index" */))
const _1e2e4400 = () => interopDefault(import('../pages/admin/master/jenis-kas/index.vue' /* webpackChunkName: "pages/admin/master/jenis-kas/index" */))
const _30b377a1 = () => interopDefault(import('../pages/admin/master/jenis-pinjaman/index.vue' /* webpackChunkName: "pages/admin/master/jenis-pinjaman/index" */))
const _212cbc94 = () => interopDefault(import('../pages/admin/master/jenis-rekening/index.vue' /* webpackChunkName: "pages/admin/master/jenis-rekening/index" */))
const _b30277b4 = () => interopDefault(import('../pages/admin/master/jenis-simpanan/index.vue' /* webpackChunkName: "pages/admin/master/jenis-simpanan/index" */))
const _6d2a5460 = () => interopDefault(import('../pages/admin/master/lembaga/index.vue' /* webpackChunkName: "pages/admin/master/lembaga/index" */))
const _6c772dd4 = () => interopDefault(import('../pages/admin/master/news/index.vue' /* webpackChunkName: "pages/admin/master/news/index" */))
const _b940d820 = () => interopDefault(import('../pages/admin/master/produk/index.vue' /* webpackChunkName: "pages/admin/master/produk/index" */))
const _17b291a4 = () => interopDefault(import('../pages/admin/master/setting-institution/index.vue' /* webpackChunkName: "pages/admin/master/setting-institution/index" */))
const _293b80e8 = () => interopDefault(import('../pages/admin/master/transaction-type/index.vue' /* webpackChunkName: "pages/admin/master/transaction-type/index" */))
const _5cec9011 = () => interopDefault(import('../pages/admin/pengaturan/management-user/index.vue' /* webpackChunkName: "pages/admin/pengaturan/management-user/index" */))
const _20fda52e = () => interopDefault(import('../pages/admin/pengaturan/permission/index.vue' /* webpackChunkName: "pages/admin/pengaturan/permission/index" */))
const _4a2f1e90 = () => interopDefault(import('../pages/admin/pengaturan/role/index.vue' /* webpackChunkName: "pages/admin/pengaturan/role/index" */))
const _0638e895 = () => interopDefault(import('../pages/admin/pengaturan/role-has-permission/index.vue' /* webpackChunkName: "pages/admin/pengaturan/role-has-permission/index" */))
const _a64b521e = () => interopDefault(import('../pages/admin/transaksi/monitoring-admin/index.vue' /* webpackChunkName: "pages/admin/transaksi/monitoring-admin/index" */))
const _188e6968 = () => interopDefault(import('../pages/admin/transaksi/penarikan/index.vue' /* webpackChunkName: "pages/admin/transaksi/penarikan/index" */))
const _100667fb = () => interopDefault(import('../pages/admin/transaksi/penarikan-otomatis/index.vue' /* webpackChunkName: "pages/admin/transaksi/penarikan-otomatis/index" */))
const _8e10bd2e = () => interopDefault(import('../pages/admin/transaksi/setoran-tunai/index.vue' /* webpackChunkName: "pages/admin/transaksi/setoran-tunai/index" */))
const _f09f6048 = () => interopDefault(import('../pages/host/flip/form.vue' /* webpackChunkName: "pages/host/flip/form" */))
const _7cf5cc10 = () => interopDefault(import('../pages/admin/core/akun-keuangan/create.vue' /* webpackChunkName: "pages/admin/core/akun-keuangan/create" */))
const _39306503 = () => interopDefault(import('../pages/admin/core/jurnal-umum/create.vue' /* webpackChunkName: "pages/admin/core/jurnal-umum/create" */))
const _3f184ba1 = () => interopDefault(import('../pages/admin/customer-service/blast-wa/template/index.vue' /* webpackChunkName: "pages/admin/customer-service/blast-wa/template/index" */))
const _8eba13c2 = () => interopDefault(import('../pages/admin/customer-service/nasabah/create.vue' /* webpackChunkName: "pages/admin/customer-service/nasabah/create" */))
const _737e4cd7 = () => interopDefault(import('../pages/admin/customer-service/nasabah/export.vue' /* webpackChunkName: "pages/admin/customer-service/nasabah/export" */))
const _1e203732 = () => interopDefault(import('../pages/admin/customer-service/nasabah/rekening/index.vue' /* webpackChunkName: "pages/admin/customer-service/nasabah/rekening/index" */))
const _588958a4 = () => interopDefault(import('../pages/admin/customer-service/nasabah/upload.vue' /* webpackChunkName: "pages/admin/customer-service/nasabah/upload" */))
const _3295c1b8 = () => interopDefault(import('../pages/admin/customer-service/registrasi/create.vue' /* webpackChunkName: "pages/admin/customer-service/registrasi/create" */))
const _d892b7d0 = () => interopDefault(import('../pages/admin/customer-service/wali/create.vue' /* webpackChunkName: "pages/admin/customer-service/wali/create" */))
const _7e968960 = () => interopDefault(import('../pages/admin/customer-service/wali/rekening/index.vue' /* webpackChunkName: "pages/admin/customer-service/wali/rekening/index" */))
const _38947d5f = () => interopDefault(import('../pages/admin/master/bank/create.vue' /* webpackChunkName: "pages/admin/master/bank/create" */))
const _66fef3df = () => interopDefault(import('../pages/admin/master/bcn-bank/create.vue' /* webpackChunkName: "pages/admin/master/bcn-bank/create" */))
const _5970a882 = () => interopDefault(import('../pages/admin/master/document-type/create.vue' /* webpackChunkName: "pages/admin/master/document-type/create" */))
const _6162f284 = () => interopDefault(import('../pages/admin/master/jenis-kas/create.vue' /* webpackChunkName: "pages/admin/master/jenis-kas/create" */))
const _0d6fc73d = () => interopDefault(import('../pages/admin/master/jenis-pinjaman/create.vue' /* webpackChunkName: "pages/admin/master/jenis-pinjaman/create" */))
const _2c1f20aa = () => interopDefault(import('../pages/admin/master/jenis-rekening/create.vue' /* webpackChunkName: "pages/admin/master/jenis-rekening/create" */))
const _510e0b58 = () => interopDefault(import('../pages/admin/master/jenis-simpanan/create.vue' /* webpackChunkName: "pages/admin/master/jenis-simpanan/create" */))
const _3ec1e112 = () => interopDefault(import('../pages/admin/master/lembaga/bcn-code/index.vue' /* webpackChunkName: "pages/admin/master/lembaga/bcn-code/index" */))
const _5fd4825e = () => interopDefault(import('../pages/admin/master/lembaga/create.vue' /* webpackChunkName: "pages/admin/master/lembaga/create" */))
const _d305f730 = () => interopDefault(import('../pages/admin/master/news/create.vue' /* webpackChunkName: "pages/admin/master/news/create" */))
const _1f719664 = () => interopDefault(import('../pages/admin/master/produk/create.vue' /* webpackChunkName: "pages/admin/master/produk/create" */))
const _aecc069c = () => interopDefault(import('../pages/admin/master/transaction-type/create.vue' /* webpackChunkName: "pages/admin/master/transaction-type/create" */))
const _6859bccd = () => interopDefault(import('../pages/admin/pengaturan/management-user/create.vue' /* webpackChunkName: "pages/admin/pengaturan/management-user/create" */))
const _2858ca75 = () => interopDefault(import('../pages/admin/pengaturan/permission/create.vue' /* webpackChunkName: "pages/admin/pengaturan/permission/create" */))
const _2368fe2e = () => interopDefault(import('../pages/admin/pengaturan/role/create.vue' /* webpackChunkName: "pages/admin/pengaturan/role/create" */))
const _187ae223 = () => interopDefault(import('../pages/admin/transaksi/penarikan-otomatis/create.vue' /* webpackChunkName: "pages/admin/transaksi/penarikan-otomatis/create" */))
const _20f30e56 = () => interopDefault(import('../pages/admin/transaksi/penarikan/create.vue' /* webpackChunkName: "pages/admin/transaksi/penarikan/create" */))
const _e49e5316 = () => interopDefault(import('../pages/admin/transaksi/setoran-tunai/create.vue' /* webpackChunkName: "pages/admin/transaksi/setoran-tunai/create" */))
const _68b51986 = () => interopDefault(import('../pages/admin/customer-service/blast-wa/template/create.vue' /* webpackChunkName: "pages/admin/customer-service/blast-wa/template/create" */))
const _799744f6 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _29d6c249 = () => interopDefault(import('../pages/admin/customer-service/blast-wa/template/_id.vue' /* webpackChunkName: "pages/admin/customer-service/blast-wa/template/_id" */))
const _6cffe524 = () => interopDefault(import('../pages/admin/core/akun-keuangan/_id.vue' /* webpackChunkName: "pages/admin/core/akun-keuangan/_id" */))
const _4338faa7 = () => interopDefault(import('../pages/admin/customer-service/nasabah/_id.vue' /* webpackChunkName: "pages/admin/customer-service/nasabah/_id" */))
const _0f27b74e = () => interopDefault(import('../pages/admin/customer-service/wali/_id.vue' /* webpackChunkName: "pages/admin/customer-service/wali/_id" */))
const _33c6bb67 = () => interopDefault(import('../pages/admin/master/bank/_id.vue' /* webpackChunkName: "pages/admin/master/bank/_id" */))
const _39d3d4e7 = () => interopDefault(import('../pages/admin/master/bcn-bank/_id.vue' /* webpackChunkName: "pages/admin/master/bcn-bank/_id" */))
const _b0adadf2 = () => interopDefault(import('../pages/admin/master/document-type/_id.vue' /* webpackChunkName: "pages/admin/master/document-type/_id" */))
const _187d4668 = () => interopDefault(import('../pages/admin/master/jenis-kas/_id.vue' /* webpackChunkName: "pages/admin/master/jenis-kas/_id" */))
const _3f66236e = () => interopDefault(import('../pages/admin/master/jenis-pinjaman/_id.vue' /* webpackChunkName: "pages/admin/master/jenis-pinjaman/_id" */))
const _5828d3fc = () => interopDefault(import('../pages/admin/master/jenis-rekening/_id.vue' /* webpackChunkName: "pages/admin/master/jenis-rekening/_id" */))
const _61c8cfe4 = () => interopDefault(import('../pages/admin/master/jenis-simpanan/_id.vue' /* webpackChunkName: "pages/admin/master/jenis-simpanan/_id" */))
const _93fc2270 = () => interopDefault(import('../pages/admin/master/lembaga/_id.vue' /* webpackChunkName: "pages/admin/master/lembaga/_id" */))
const _1c65b8fe = () => interopDefault(import('../pages/admin/master/news/_id.vue' /* webpackChunkName: "pages/admin/master/news/_id" */))
const _14a1d258 = () => interopDefault(import('../pages/admin/master/produk/_id.vue' /* webpackChunkName: "pages/admin/master/produk/_id" */))
const _c296a5d4 = () => interopDefault(import('../pages/admin/master/setting-institution/_id.vue' /* webpackChunkName: "pages/admin/master/setting-institution/_id" */))
const _755a9618 = () => interopDefault(import('../pages/admin/master/transaction-type/_id.vue' /* webpackChunkName: "pages/admin/master/transaction-type/_id" */))
const _485d3a8e = () => interopDefault(import('../pages/admin/pengaturan/management-user/_id.vue' /* webpackChunkName: "pages/admin/pengaturan/management-user/_id" */))
const _ddc513de = () => interopDefault(import('../pages/admin/pengaturan/permission/_id.vue' /* webpackChunkName: "pages/admin/pengaturan/permission/_id" */))
const _12bbe786 = () => interopDefault(import('../pages/admin/pengaturan/role-has-permission/_id.vue' /* webpackChunkName: "pages/admin/pengaturan/role-has-permission/_id" */))
const _111384f8 = () => interopDefault(import('../pages/admin/pengaturan/role/_id.vue' /* webpackChunkName: "pages/admin/pengaturan/role/_id" */))
const _51212220 = () => interopDefault(import('../pages/page/cek-status-booking/_id.vue' /* webpackChunkName: "pages/page/cek-status-booking/_id" */))
const _fc45a338 = () => interopDefault(import('../pages/auth/_registerBak.vue' /* webpackChunkName: "pages/auth/_registerBak" */))
const _79286d0e = () => interopDefault(import('../pages/login-history/_id.vue' /* webpackChunkName: "pages/login-history/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/daftar-implementasi-pesantren",
    component: _751369c0,
    name: "daftar-implementasi-pesantren"
  }, {
    path: "/login-history",
    component: _00aa5926,
    name: "login-history"
  }, {
    path: "/ppob",
    component: _1a2f94f3,
    name: "ppob"
  }, {
    path: "/profile",
    component: _312e219f,
    name: "profile"
  }, {
    path: "/read-more",
    component: _427f035f,
    name: "read-more"
  }, {
    path: "/santri",
    component: _3e577fab,
    name: "santri"
  }, {
    path: "/admin/home",
    component: _1cb4447c,
    name: "admin-home"
  }, {
    path: "/auth/login",
    component: _178818c2,
    name: "auth-login"
  }, {
    path: "/auth/register",
    component: _ea16e646,
    name: "auth-register"
  }, {
    path: "/daftar-implementasi-pesantren/success",
    component: _212802f1,
    name: "daftar-implementasi-pesantren-success"
  }, {
    path: "/host/flip",
    component: _560c402c,
    name: "host-flip"
  }, {
    path: "/host/midtrans",
    component: _7b9d37c6,
    name: "host-midtrans"
  }, {
    path: "/host/otto",
    component: _0cc8da46,
    name: "host-otto"
  }, {
    path: "/host/pay",
    component: _ce79f644,
    name: "host-pay"
  }, {
    path: "/login-history/create",
    component: _3c551658,
    name: "login-history-create"
  }, {
    path: "/page/cek-status-booking",
    component: _856bdc90,
    name: "page-cek-status-booking"
  }, {
    path: "/page/kontak-kami",
    component: _51335368,
    name: "page-kontak-kami"
  }, {
    path: "/page/peta-situs",
    component: _a1824ef8,
    name: "page-peta-situs"
  }, {
    path: "/page/privacy-policy",
    component: _01e81cec,
    name: "page-privacy-policy"
  }, {
    path: "/page/tentang-kami",
    component: _0d77a92f,
    name: "page-tentang-kami"
  }, {
    path: "/santri/profil",
    component: _20efbdb3,
    name: "santri-profil"
  }, {
    path: "/admin/core/akun-keuangan",
    component: _ccc93cf4,
    name: "admin-core-akun-keuangan"
  }, {
    path: "/admin/core/buku-besar",
    component: _5a04ef52,
    name: "admin-core-buku-besar"
  }, {
    path: "/admin/core/jurnal-khusus",
    component: _8cb05978,
    name: "admin-core-jurnal-khusus"
  }, {
    path: "/admin/core/jurnal-penyesuaian",
    component: _0d9965b2,
    name: "admin-core-jurnal-penyesuaian"
  }, {
    path: "/admin/core/jurnal-umum",
    component: _5325091b,
    name: "admin-core-jurnal-umum"
  }, {
    path: "/admin/core/kertas-kerja",
    component: _15d7ef94,
    name: "admin-core-kertas-kerja"
  }, {
    path: "/admin/core/laba-rugi",
    component: _5a2ba9ce,
    name: "admin-core-laba-rugi"
  }, {
    path: "/admin/core/laporan-keuangan",
    component: _cc5d9fd8,
    name: "admin-core-laporan-keuangan"
  }, {
    path: "/admin/core/neraca",
    component: _6be0d1ac,
    name: "admin-core-neraca"
  }, {
    path: "/admin/customer-service/blast-wa",
    component: _85dea744,
    name: "admin-customer-service-blast-wa"
  }, {
    path: "/admin/customer-service/nasabah",
    component: _ee643702,
    name: "admin-customer-service-nasabah"
  }, {
    path: "/admin/customer-service/registrasi",
    component: _610006da,
    name: "admin-customer-service-registrasi"
  }, {
    path: "/admin/customer-service/rekening-koran",
    component: _4c9941e4,
    name: "admin-customer-service-rekening-koran"
  }, {
    path: "/admin/customer-service/rekening-wali",
    component: _6d2e57aa,
    name: "admin-customer-service-rekening-wali"
  }, {
    path: "/admin/customer-service/riwayat-transfer",
    component: _194bb80c,
    name: "admin-customer-service-riwayat-transfer"
  }, {
    path: "/admin/customer-service/tagihan-spp",
    component: _3f6f6be3,
    name: "admin-customer-service-tagihan-spp"
  }, {
    path: "/admin/customer-service/transaksi",
    component: _a5426202,
    name: "admin-customer-service-transaksi"
  }, {
    path: "/admin/customer-service/wali",
    component: _14001366,
    name: "admin-customer-service-wali"
  }, {
    path: "/admin/master/bank",
    component: _e6231582,
    name: "admin-master-bank"
  }, {
    path: "/admin/master/bcn-bank",
    component: _441b2ebf,
    name: "admin-master-bcn-bank"
  }, {
    path: "/admin/master/document-type",
    component: _dc280a42,
    name: "admin-master-document-type"
  }, {
    path: "/admin/master/jenis-kas",
    component: _1e2e4400,
    name: "admin-master-jenis-kas"
  }, {
    path: "/admin/master/jenis-pinjaman",
    component: _30b377a1,
    name: "admin-master-jenis-pinjaman"
  }, {
    path: "/admin/master/jenis-rekening",
    component: _212cbc94,
    name: "admin-master-jenis-rekening"
  }, {
    path: "/admin/master/jenis-simpanan",
    component: _b30277b4,
    name: "admin-master-jenis-simpanan"
  }, {
    path: "/admin/master/lembaga",
    component: _6d2a5460,
    name: "admin-master-lembaga"
  }, {
    path: "/admin/master/news",
    component: _6c772dd4,
    name: "admin-master-news"
  }, {
    path: "/admin/master/produk",
    component: _b940d820,
    name: "admin-master-produk"
  }, {
    path: "/admin/master/setting-institution",
    component: _17b291a4,
    name: "admin-master-setting-institution"
  }, {
    path: "/admin/master/transaction-type",
    component: _293b80e8,
    name: "admin-master-transaction-type"
  }, {
    path: "/admin/pengaturan/management-user",
    component: _5cec9011,
    name: "admin-pengaturan-management-user"
  }, {
    path: "/admin/pengaturan/permission",
    component: _20fda52e,
    name: "admin-pengaturan-permission"
  }, {
    path: "/admin/pengaturan/role",
    component: _4a2f1e90,
    name: "admin-pengaturan-role"
  }, {
    path: "/admin/pengaturan/role-has-permission",
    component: _0638e895,
    name: "admin-pengaturan-role-has-permission"
  }, {
    path: "/admin/transaksi/monitoring-admin",
    component: _a64b521e,
    name: "admin-transaksi-monitoring-admin"
  }, {
    path: "/admin/transaksi/penarikan",
    component: _188e6968,
    name: "admin-transaksi-penarikan"
  }, {
    path: "/admin/transaksi/penarikan-otomatis",
    component: _100667fb,
    name: "admin-transaksi-penarikan-otomatis"
  }, {
    path: "/admin/transaksi/setoran-tunai",
    component: _8e10bd2e,
    name: "admin-transaksi-setoran-tunai"
  }, {
    path: "/host/flip/form",
    component: _f09f6048,
    name: "host-flip-form"
  }, {
    path: "/admin/core/akun-keuangan/create",
    component: _7cf5cc10,
    name: "admin-core-akun-keuangan-create"
  }, {
    path: "/admin/core/jurnal-umum/create",
    component: _39306503,
    name: "admin-core-jurnal-umum-create"
  }, {
    path: "/admin/customer-service/blast-wa/template",
    component: _3f184ba1,
    name: "admin-customer-service-blast-wa-template"
  }, {
    path: "/admin/customer-service/nasabah/create",
    component: _8eba13c2,
    name: "admin-customer-service-nasabah-create"
  }, {
    path: "/admin/customer-service/nasabah/export",
    component: _737e4cd7,
    name: "admin-customer-service-nasabah-export"
  }, {
    path: "/admin/customer-service/nasabah/rekening",
    component: _1e203732,
    name: "admin-customer-service-nasabah-rekening"
  }, {
    path: "/admin/customer-service/nasabah/upload",
    component: _588958a4,
    name: "admin-customer-service-nasabah-upload"
  }, {
    path: "/admin/customer-service/registrasi/create",
    component: _3295c1b8,
    name: "admin-customer-service-registrasi-create"
  }, {
    path: "/admin/customer-service/wali/create",
    component: _d892b7d0,
    name: "admin-customer-service-wali-create"
  }, {
    path: "/admin/customer-service/wali/rekening",
    component: _7e968960,
    name: "admin-customer-service-wali-rekening"
  }, {
    path: "/admin/master/bank/create",
    component: _38947d5f,
    name: "admin-master-bank-create"
  }, {
    path: "/admin/master/bcn-bank/create",
    component: _66fef3df,
    name: "admin-master-bcn-bank-create"
  }, {
    path: "/admin/master/document-type/create",
    component: _5970a882,
    name: "admin-master-document-type-create"
  }, {
    path: "/admin/master/jenis-kas/create",
    component: _6162f284,
    name: "admin-master-jenis-kas-create"
  }, {
    path: "/admin/master/jenis-pinjaman/create",
    component: _0d6fc73d,
    name: "admin-master-jenis-pinjaman-create"
  }, {
    path: "/admin/master/jenis-rekening/create",
    component: _2c1f20aa,
    name: "admin-master-jenis-rekening-create"
  }, {
    path: "/admin/master/jenis-simpanan/create",
    component: _510e0b58,
    name: "admin-master-jenis-simpanan-create"
  }, {
    path: "/admin/master/lembaga/bcn-code",
    component: _3ec1e112,
    name: "admin-master-lembaga-bcn-code"
  }, {
    path: "/admin/master/lembaga/create",
    component: _5fd4825e,
    name: "admin-master-lembaga-create"
  }, {
    path: "/admin/master/news/create",
    component: _d305f730,
    name: "admin-master-news-create"
  }, {
    path: "/admin/master/produk/create",
    component: _1f719664,
    name: "admin-master-produk-create"
  }, {
    path: "/admin/master/transaction-type/create",
    component: _aecc069c,
    name: "admin-master-transaction-type-create"
  }, {
    path: "/admin/pengaturan/management-user/create",
    component: _6859bccd,
    name: "admin-pengaturan-management-user-create"
  }, {
    path: "/admin/pengaturan/permission/create",
    component: _2858ca75,
    name: "admin-pengaturan-permission-create"
  }, {
    path: "/admin/pengaturan/role/create",
    component: _2368fe2e,
    name: "admin-pengaturan-role-create"
  }, {
    path: "/admin/transaksi/penarikan-otomatis/create",
    component: _187ae223,
    name: "admin-transaksi-penarikan-otomatis-create"
  }, {
    path: "/admin/transaksi/penarikan/create",
    component: _20f30e56,
    name: "admin-transaksi-penarikan-create"
  }, {
    path: "/admin/transaksi/setoran-tunai/create",
    component: _e49e5316,
    name: "admin-transaksi-setoran-tunai-create"
  }, {
    path: "/admin/customer-service/blast-wa/template/create",
    component: _68b51986,
    name: "admin-customer-service-blast-wa-template-create"
  }, {
    path: "/",
    component: _799744f6,
    name: "index"
  }, {
    path: "/admin/customer-service/blast-wa/template/:id?",
    component: _29d6c249,
    name: "admin-customer-service-blast-wa-template-id"
  }, {
    path: "/admin/core/akun-keuangan/:id?",
    component: _6cffe524,
    name: "admin-core-akun-keuangan-id"
  }, {
    path: "/admin/customer-service/nasabah/:id?",
    component: _4338faa7,
    name: "admin-customer-service-nasabah-id"
  }, {
    path: "/admin/customer-service/wali/:id?",
    component: _0f27b74e,
    name: "admin-customer-service-wali-id"
  }, {
    path: "/admin/master/bank/:id",
    component: _33c6bb67,
    name: "admin-master-bank-id"
  }, {
    path: "/admin/master/bcn-bank/:id?",
    component: _39d3d4e7,
    name: "admin-master-bcn-bank-id"
  }, {
    path: "/admin/master/document-type/:id?",
    component: _b0adadf2,
    name: "admin-master-document-type-id"
  }, {
    path: "/admin/master/jenis-kas/:id?",
    component: _187d4668,
    name: "admin-master-jenis-kas-id"
  }, {
    path: "/admin/master/jenis-pinjaman/:id?",
    component: _3f66236e,
    name: "admin-master-jenis-pinjaman-id"
  }, {
    path: "/admin/master/jenis-rekening/:id?",
    component: _5828d3fc,
    name: "admin-master-jenis-rekening-id"
  }, {
    path: "/admin/master/jenis-simpanan/:id?",
    component: _61c8cfe4,
    name: "admin-master-jenis-simpanan-id"
  }, {
    path: "/admin/master/lembaga/:id",
    component: _93fc2270,
    name: "admin-master-lembaga-id"
  }, {
    path: "/admin/master/news/:id",
    component: _1c65b8fe,
    name: "admin-master-news-id"
  }, {
    path: "/admin/master/produk/:id",
    component: _14a1d258,
    name: "admin-master-produk-id"
  }, {
    path: "/admin/master/setting-institution/:id?",
    component: _c296a5d4,
    name: "admin-master-setting-institution-id"
  }, {
    path: "/admin/master/transaction-type/:id?",
    component: _755a9618,
    name: "admin-master-transaction-type-id"
  }, {
    path: "/admin/pengaturan/management-user/:id?",
    component: _485d3a8e,
    name: "admin-pengaturan-management-user-id"
  }, {
    path: "/admin/pengaturan/permission/:id",
    component: _ddc513de,
    name: "admin-pengaturan-permission-id"
  }, {
    path: "/admin/pengaturan/role-has-permission/:id",
    component: _12bbe786,
    name: "admin-pengaturan-role-has-permission-id"
  }, {
    path: "/admin/pengaturan/role/:id",
    component: _111384f8,
    name: "admin-pengaturan-role-id"
  }, {
    path: "/page/cek-status-booking/:id?",
    component: _51212220,
    name: "page-cek-status-booking-id"
  }, {
    path: "/auth/:registerBak?",
    component: _fc45a338,
    name: "auth-registerBak"
  }, {
    path: "/login-history/:id?",
    component: _79286d0e,
    name: "login-history-id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}

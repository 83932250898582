export const Logo = () => import('../../components/Logo.vue' /* webpackChunkName: "components/logo" */).then(c => wrapFunctional(c.default || c))
export const VuetifyLogo = () => import('../../components/VuetifyLogo.vue' /* webpackChunkName: "components/vuetify-logo" */).then(c => wrapFunctional(c.default || c))
export const Core = () => import('../../components/core/404.vue' /* webpackChunkName: "components/core" */).then(c => wrapFunctional(c.default || c))
export const CoreAppBar = () => import('../../components/core/AppBar.vue' /* webpackChunkName: "components/core-app-bar" */).then(c => wrapFunctional(c.default || c))
export const CoreDrawer = () => import('../../components/core/Drawer.vue' /* webpackChunkName: "components/core-drawer" */).then(c => wrapFunctional(c.default || c))
export const CoreFilter = () => import('../../components/core/Filter.vue' /* webpackChunkName: "components/core-filter" */).then(c => wrapFunctional(c.default || c))
export const CoreFooter = () => import('../../components/core/Footer.vue' /* webpackChunkName: "components/core-footer" */).then(c => wrapFunctional(c.default || c))
export const CoreFooterAuth = () => import('../../components/core/FooterAuth.vue' /* webpackChunkName: "components/core-footer-auth" */).then(c => wrapFunctional(c.default || c))
export const CoreView = () => import('../../components/core/View.vue' /* webpackChunkName: "components/core-view" */).then(c => wrapFunctional(c.default || c))
export const MaterialCard = () => import('../../components/material/Card.vue' /* webpackChunkName: "components/material-card" */).then(c => wrapFunctional(c.default || c))
export const MaterialCardTable = () => import('../../components/material/CardTable.vue' /* webpackChunkName: "components/material-card-table" */).then(c => wrapFunctional(c.default || c))
export const MaterialChartCard = () => import('../../components/material/ChartCard.vue' /* webpackChunkName: "components/material-chart-card" */).then(c => wrapFunctional(c.default || c))
export const MaterialNotification = () => import('../../components/material/Notification.vue' /* webpackChunkName: "components/material-notification" */).then(c => wrapFunctional(c.default || c))
export const MaterialStatsCard = () => import('../../components/material/StatsCard.vue' /* webpackChunkName: "components/material-stats-card" */).then(c => wrapFunctional(c.default || c))
export const HelperEditor = () => import('../../components/helper/Editor.vue' /* webpackChunkName: "components/helper-editor" */).then(c => wrapFunctional(c.default || c))
export const HelperOffset = () => import('../../components/helper/Offset.vue' /* webpackChunkName: "components/helper-offset" */).then(c => wrapFunctional(c.default || c))
export const CoreGuestAppBar = () => import('../../components/core/guest/AppBar.vue' /* webpackChunkName: "components/core-guest-app-bar" */).then(c => wrapFunctional(c.default || c))
export const CoreGuestCarousel = () => import('../../components/core/guest/Carousel.vue' /* webpackChunkName: "components/core-guest-carousel" */).then(c => wrapFunctional(c.default || c))
export const CoreGuestFooter = () => import('../../components/core/guest/Footer.vue' /* webpackChunkName: "components/core-guest-footer" */).then(c => wrapFunctional(c.default || c))
export const CoreGuestView = () => import('../../components/core/guest/View.vue' /* webpackChunkName: "components/core-guest-view" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
